<template>
  <div v-loading="state.loading">
    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item>
          <el-select v-model="state.filter.categories" placeholder="Категории услуг" multiple clearable filterable>
            <el-option v-for="item in categories" :label="item.title" :value="item.id" :key="item.id"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item>
          <el-select
            v-model="state.filter.masters"
            placeholder="Мастера"
            multiple
            clearable
            filterable
            remote
            :remote-method="remoteFilterMastersList"
          >
            <el-option v-for="item in masters" :label="item.title" :value="item.id" :key="item.id"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item>
          <el-select v-model="state.filter.locations" placeholder="Локации" multiple clearable filterable>
            <el-option v-for="item in locations" :label="item.title" :value="item.id" :key="item.id"/>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="2">
        <el-button type="success" @click="doFilter">Применить</el-button>
      </el-col>

      <el-col :xs="24" :span="4">
        <el-form-item>
          <el-input v-model="state.filter.address" placeholder="Адрес" clearable/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-table max-height="500" show-summary :data="tableData" :summary-method="getSummaries"
              @cell-click="handlerClickCell">
      <el-table-column type="index" label="#" :index="indexMethod"/>

      <el-table-column class-name="master-cell-wrap" header-align="center" :min-width="200">
        <template #header>
          <el-row justify="space-between">
            <router-link
              class="date-link"
              :to="{ query: {...route.query, startDate: prevMoment.format('YYYY-MM-DD')} }"
            >←{{ prevMoment.format('DD.MM.YYYY') }}
            </router-link>
            <router-link
              class="date-link"
              :to="{ query: {...route.query, startDate: nextMoment.format('YYYY-MM-DD')} }"
            >{{ nextMoment.format('DD.MM.YYYY') }}→
            </router-link>
          </el-row>
        </template>

        <template #default="{ row }">
          <el-link
            v-if="row.numUndeterminedOrders"
            class="undetermined-orders"
            :href="getUndeterminedOrdersPath(row.id)"
            type="danger"
            target="_blank"
          >{{ row.numUndeterminedOrders }}
          </el-link>
          <el-link @click="$emit('editRecord', row.id)">{{ row.title }}</el-link>
        </template>
      </el-table-column>

      <el-table-column
        v-for="tableDay in tableDays"
        :key="tableDay.day"
        :other-data="tableDay"
        :class-name="getDayCellClass(tableDay)"
        header-align="center"
        align="center"
        prop="day"
      >
        <template #header>
          {{ tableDay.formattedDay }}
        </template>

        <template #default="{ row }">
          <el-icon
            v-if="row.weekends.find(weekend => weekend.date.slice(-2) === tableDay.formattedDay)"
            :size="16"
            color="var(--el-color-danger)"
          >
            <Close/>
          </el-icon>
          <el-link
            v-if="row.numActiveOrdersByDate[tableDay.formattedDate]"
            :href="getActiveOrdersPath(row.id, tableDay.formattedDate)"
            target="_blank"
          >{{ row.numActiveOrdersByDate[tableDay.formattedDate] }}
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-row justify="end">
      <el-pagination background layout="prev, pager, next" :total="state._meta.totalCount"
                     :page-size="state._meta.perPage" v-model:current-page="state.filter.page"
                     :page-count="state._meta.pageCount" @update:current-page="doFilter"/>
    </el-row>
  </div>
</template>

<script setup lang="ts">

import { computed, h, onMounted, reactive, ref, VNode, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'
import restServiceCategories from '@/api/rest/restServiceCategories'
import { iLocation, iMaster, iServiceCategory } from '@/types/models'
import { ElMessage, TableColumnCtx } from 'element-plus'
import restMasters from '@/api/rest/restMasters'
import restLocations from '@/api/rest/restLocations'
import { isArray } from 'lodash'
import { Close } from '@element-plus/icons-vue'
import { orderStatusIds } from '@/data/orderStatus'
import { orderPaymentIds } from '@/data/orderPayment'
import restMastersWeekends from '@/api/rest/restMastersWeekends'
import { iRestCollectionMeta } from '@/types/responses'

interface iFilter {
  startDate: string
  categories: number[]
  masters: number[]
  locations: number[]
  address: string
  page: number
  perPage: number
}

interface iTableDay {
  day: number
  formattedDay: string
  formattedDate: string
  isSaturday: boolean
  isSunday: boolean
}

interface TableRowData extends iMaster {
  numUndeterminedOrders: number
  numActiveOrdersByDate: {
    [K: string]: number
  }
}

interface SummaryMethodProps<T = TableRowData> {
  columns: TableColumnCtx<T>[]
  data: T[]
}

defineEmits(['editRecord'])

const route = useRoute()
const router = useRouter()
const categories = ref<iServiceCategory[]>([])
const masters = ref<iMaster[]>([])
const locations = ref<iLocation[]>([])
const daysCount = 14
const nullMoment = moment(0)
const perPage = 20

const state = reactive<{
  items: iMaster[]
  filter: iFilter,
  loading: boolean
  _meta: Partial<iRestCollectionMeta>
}>({
  loading: true,
  filter: {
    startDate: '',
    categories: [],
    masters: [],
    locations: [],
    address: '',
    page: Number(route.query.page) || 1,
    perPage: Number(route.query.perPage) || perPage
  },
  items: [],
  _meta: {
    totalCount: undefined,
    pageCount: undefined,
    perPage: undefined,
    currentPage: undefined
  }
})

const startMoment = computed(() => moment(state.filter.startDate))
const prevMoment = computed(() => moment(state.filter.startDate).subtract(daysCount, 'd'))
const nextMoment = computed(() => moment(state.filter.startDate).add(daysCount, 'd'))
const tableDays = computed<iTableDay[]>(() => Array(daysCount).fill(undefined).map((nullVal: null, index: number) => {
  const needMoment = startMoment.value.clone().add(index, 'd')
  const monthDay = needMoment.date()
  const weekDay = needMoment.day()

  return {
    day: monthDay,
    formattedDay: ('0' + monthDay).slice(-2),
    formattedDate: needMoment.format('YYYY-MM-DD'),
    isSaturday: weekDay === 6,
    isSunday: weekDay === 0
  }
}))
const tableData = computed<TableRowData[]>(() => state.items.map(item => {
  const numActiveOrdersByDate: {
    [K: string]: number
  } = {}

  tableDays.value.forEach(tableDay => {
    numActiveOrdersByDate[tableDay.formattedDate] = item.orders.filter(order => order.date_expected === tableDay.formattedDate).length
  })

  return {
    ...item,
    numUndeterminedOrders: item.orders.filter(
      order => !order.date_expected && !order.date_assemble
    ).length,
    numActiveOrdersByDate
  }
}))

const indexMethod = (index: number) => (index + 1) + (state.filter.page - 1) * state.filter.perPage
const getSummaries = (param: SummaryMethodProps) => {
  const { columns, data } = param
  const sums: Array<string | number | VNode> = []
  let indexDay = 0

  columns.forEach(column => {
    if (column.type === 'index') {
      sums.push('')
      return
    }

    if (column.no === 1) {
      const vNodeNum = h(
        'span',
        { class: 'undetermined-orders', style: { color: 'var(--el-color-danger)' } },
        data.reduce(
          (accumulator, row) => accumulator + row.numUndeterminedOrders,
          0
        )
      )

      sums.push(h('span', [vNodeNum, 'Итого:']))
      return
    }

    if (column.property === 'day') {
      sums.push(data.reduce(
        (accumulator, row) => accumulator + (row.numActiveOrdersByDate[tableDays.value[indexDay].formattedDate] || 0),
        0
      ))
      indexDay++
      return
    }

    sums.push('')
  })

  return sums
}
const changeWeekend = (masterId: number, date?: string) => {
  const item = state.items.find(item => item.id === masterId)

  if (!item || !date) {
    ElMessage.error('Непредвиденная ошибка')
    console.error(masterId, date)
    return
  }

  const weekendIndex = item.weekends.findIndex(weekend => weekend.date === date)

  if (weekendIndex !== -1) {
    item.weekends.splice(weekendIndex, 1)
    restMastersWeekends.delete(masterId, date)
      .catch(error => {
        console.error(error)
        ElMessage.error({
          message: `
            <p>Не удалось изменить запись</p>
            <p>Мастер: ${item.title}</p>
            <p>Дата: ${date}</p>
          `,
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true
        })
        item.weekends.push({
          master_id: masterId,
          date
        })
      })
  } else {
    item.weekends.push({
      master_id: masterId,
      date
    })
    restMastersWeekends.save({ master_id: masterId, date })
      .catch(error => {
        console.error(error)
        ElMessage.error({
          message: `
            <p>Не удалось изменить запись</p>
            <p>Мастер: ${item.title}</p>
            <p>Дата: ${date}</p>
          `,
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true
        })
        item.weekends = item.weekends.filter(weekend => weekend.master_id !== masterId || weekend.date !== date)
      })
  }
}
const handlerClickCell = (row: TableRowData, column: TableColumnCtx<TableRowData>) => {
  if (column.property === 'day') {
    const day = column.rawColumnKey as unknown as number
    const formattedDate = tableDays.value.find(tableDay => tableDay.day === day)?.formattedDate

    if (!row.numActiveOrdersByDate[formattedDate as string]) {
      changeWeekend(row.id, formattedDate)
    }
  }
}
const getDayCellClass = (tableDay: iTableDay) => {
  const resultArr = []

  if (tableDay.isSunday) {
    resultArr.push('sunday-cell')
  } else if (tableDay.isSaturday) {
    resultArr.push('saturday-cell')
  }

  return resultArr.join(' ')
}
const getActiveOrdersPath = (masterId: number, date: string) => router.resolve({
  name: 'orders',
  query: {
    dateExpected: date + ':' + date,
    masterId,
    payments: [1, 2].join(',')
  }
}).fullPath
const getUndeterminedOrdersPath = (masterId: number) => {
  const nullDate = nullMoment.format('YYYY-MM-DD')
  const nullDateRange = nullDate + ':' + nullDate

  return router.resolve({
    name: 'orders',
    query: {
      dateExpected: nullDateRange,
      dateAssemble: nullDateRange,
      masterId,
      payments: [orderPaymentIds.onSpot, orderPaymentIds.inShopAndPaid].join(','),
      statuses: [orderStatusIds.new, orderStatusIds.inWork].join(',')
    }
  }).fullPath
}
const load = () => {
  const queryParams = {
    params: {
      fields: ['id', 'title', 'weekends', 'orders'].join(','),
      ...state.filter
    }
  }

  state.loading = true

  restMasters.weekends(queryParams)
    .then(response => {
      state.items = response.data.items

      if (response.data._meta) {
        state.filter.page = response.data._meta.currentPage
        state.filter.perPage = response.data._meta.perPage
        state._meta = response.data._meta
      } else {
        state.filter.page = 1
        state.filter.perPage = perPage
        state._meta.currentPage = 1
        state._meta.pageCount = 1
        state._meta.totalCount = state.items.length
        state._meta.perPage = state.items.length
      }
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const remoteFilterMastersList = (masterTitle: string) => {
  const config = {
    params: {
      fields: ['id', 'title'].join(','),
      filter: {
        title: {
          like: masterTitle
        }
      }
    }
  }

  restMasters.get(config)
    .then(response => {
      masters.value = response.data.items
    })
    .catch(error => {
      ElMessage.error('Ошибка загрузки категорий')
      console.error(error)
    })
}
const doFilter = () => {
  const queryParams: {
    // eslint-disable-next-line no-unused-vars
    [K in keyof iFilter]?: string | number
  } = {}

  for (const keyTemp in state.filter) {
    const key = keyTemp as keyof iFilter
    const value = state.filter[key]

    if (isArray(value)) {
      if (value.length) {
        queryParams[key] = value.join(',')
      }
    } else if (value) {
      queryParams[key] = value
    }
  }

  router.push({ query: queryParams })
}
const loadCategories = () => restServiceCategories.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    categories.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Ошибка загрузки категорий')
    console.error(error)
  })
const loadLocations = () => restLocations.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    locations.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Ошибка загрузки категорий')
    console.error(error)
  })

defineExpose({
  load
})

watch(() => route.query, () => {
  const query = route.query as {
    // eslint-disable-next-line no-unused-vars
    [K in keyof iFilter]?: string
  }

  state.filter.startDate = query.startDate ?? moment().format('YYYY-MM-DD')
  state.filter.categories = query.categories?.split(',').map(Number) ?? []
  state.filter.masters = query.masters?.split(',').map(Number) ?? []
  state.filter.locations = query.locations?.split(',').map(Number) ?? []
  state.filter.address = query.address ?? ''
  state.filter.page = Number(query.page || 1)
  state.filter.perPage = Number(query.perPage || perPage)

  load()
}, { immediate: true })

onMounted(async () => {
  state.loading = true

  await Promise.all([
    loadCategories(),
    loadLocations()
  ])

  state.loading = false
})

</script>

<style scoped lang="scss">
.date-link {
  text-decoration: none;
  border-bottom: 1px dotted;
  color: inherit;
}

:deep(.el-table) {
  tbody .master-cell-wrap .cell,
  tfoot .master-cell-wrap .cell {
    padding-left: 30px;

    .undetermined-orders {
      position: absolute;
      left: 15px;
      transform: translateX(-50%);
    }
  }

  .sunday-cell, .saturday-cell {
    background-color: var(--el-color-info-light-5);
  }
}
</style>

<template>
  <el-form ref="formRef" :model="state.form" :rules="state.rules" label-position="top">
    <el-form-item prop="number" label="Номер" :error="props.errors.number" v-if="index !== undefined">
      <el-input v-model="state.form.number" :placeholder="(expectedNumber || '').toString()" />
    </el-form-item>

    <template v-if="can('access_order_price_fields')">
      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <el-form-item prop="cost" label="Сумма" :error="props.errors.cost">
            <el-input v-model.number="state.form.cost" />
          </el-form-item>
        </el-col>

        <el-col :span="6" :xs="24">
          <el-form-item prop="payment_part_extra" label="Сумма доп работ" :error="props.errors.payment_part_extra">
            <el-input v-model.number="state.form.payment_part_extra" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <el-form-item prop="is_payment_immediately" label="Оплата на месте">
            <el-checkbox v-model="state.form.is_payment_immediately" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <el-form-item prop="payment_part_shop" label="Сумма магазину" :error="props.errors.payment_part_shop">
            <el-input v-model.number="state.form.payment_part_shop" @input="unlinkCalculation('payment_part_shop')">
              <template v-if="!state.linksToCalculation.payment_part_shop" #append>
                <el-tooltip>
                  <template #content>
                    Применить расчет<br/>
                    Сумма заявки + процент магазина
                  </template>

                  <el-button :icon="Connection" @click="handlerConnectionCalc('payment_part_shop')" />
                </el-tooltip>
              </template>
            </el-input>
            <input-description v-show="state.form.payment_part_shop !== calculationPaymentShop">
              Расчет: {{ calculationPaymentShop }}
            </input-description>
          </el-form-item>
        </el-col>

        <el-col :span="6" :xs="24">
          <el-form-item prop="payment_date_shop" label="Дата оплаты магазину" :error="props.errors.payment_date_shop">
            <el-date-picker
              v-model="state.form.payment_date_shop"
              value-format="YYYY-MM-DD"
              format="DD.MM.YYYY"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <el-form-item prop="payment_part_master" label="Сумма мастеру осн" :error="props.errors.payment_part_master">
            <el-input v-model.number="state.form.payment_part_master" @input="unlinkCalculation('payment_part_master')">
              <template v-if="!state.linksToCalculation.payment_part_master" #append>
                <el-tooltip>
                  <template #content>
                    Применить расчет<br/>
                    Сумма заявки + процент {{ state.form.is_payment_immediately ? 'мастера' : 'магазина' }}
                  </template>

                  <el-button :icon="Connection" @click="handlerConnectionCalc('payment_part_master')" />
                </el-tooltip>
              </template>
            </el-input>
            <input-description v-show="state.form.payment_part_master !== calculationPaymentMaster">
              Расчет: {{ calculationPaymentMaster }}
            </input-description>
          </el-form-item>
        </el-col>

        <el-col :span="6" :xs="24">
          <el-form-item prop="payment_part_master_extra" label="Сумма мастеру доп" :error="props.errors.payment_part_master_extra">
            <el-input v-model.number="state.form.payment_part_master_extra" @input="unlinkCalculation('payment_part_master_extra')">
              <template v-if="!state.linksToCalculation.payment_part_master_extra" #append>
                <el-tooltip>
                  <template #content>
                    Связать с расчетом<br/>
                    Сумма заявки + процент {{ state.form.is_payment_immediately ? 'мастера доп' : 'магазина доп' }}
                  </template>

                  <el-button :icon="Connection" @click="handlerConnectionCalc('payment_part_master_extra')" />
                </el-tooltip>
              </template>
            </el-input>
            <input-description v-show="state.form.payment_part_master_extra !== calculationPaymentMasterExtra">
              Расчет: {{ calculationPaymentMasterExtra }}
            </input-description>
          </el-form-item>
        </el-col>

        <el-col :span="6" :xs="24">
          <el-form-item prop="payment_date_master" label="Дата опл мастеру" :error="props.errors.payment_date_master">
            <el-date-picker
              v-model="state.form.payment_date_master"
              value-format="YYYY-MM-DD"
              format="DD.MM.YYYY"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6" :xs="24">
          <el-form-item label="Долг мастера">
            <el-input v-model="state.form.master_debt" disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item prop="note" :error="props.errors.note" label="Примечания">
        <el-input type="textarea" v-model="state.form.note" />
      </el-form-item>

      <el-form-item prop="note_inner" :error="props.errors.note_inner" label="Внутренние примечания">
        <el-input type="textarea" v-model="state.form.note_inner" />
      </el-form-item>
    </template>

    <el-form-item label="Файлы">
      <order-file-upload v-model="state.form.files" />
    </el-form-item>

    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item prop="partner_id" :error="props.errors.partner_id" label="Партнер">
          <el-select
            v-model="state.form.partner_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in partners"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item class="master_id-form_item" prop="master_id" :error="props.errors.master_id" label="Мастер">
          <el-link class="select_master-link" :href="selectMasterPath" type="warning" target="_blank">
            Подобрать
          </el-link>
          <master-autocomplete v-model="state.form.master_id" clearable />
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item prop="pre_master_id" :error="props.errors.pre_master_id" label="Мастер предварительно">
          <master-autocomplete v-model="state.form.pre_master_id" clearable />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6" :xs="24">
        <el-form-item prop="status_id" :error="props.errors.status_id" label="Статус">
          <el-select
            v-model="state.form.status_id"
          >
            <el-option
              v-for="option in statuses"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="5">
        <el-form-item prop="is_doc_passed" label="Документы сданы">
          <el-checkbox v-model="state.form.is_doc_passed" />
        </el-form-item>
      </el-col>

      <el-col :span="7">
        <el-form-item prop="has_debt" label="Долг по основной сумме">
          <el-checkbox v-model="state.form.has_debt" />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="24">
        <el-form-item prop="has_debt_extra" label="Долг по доп. сумме">
          <el-checkbox v-model="state.form.has_debt_extra" />
        </el-form-item>
      </el-col>
    </el-row>

    <order-summary-table v-if="props.orderType && props.services !== null" :items="props.services" />
  </el-form>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useStore } from '@/store'
import { PermissionCanFunctionType } from '@/data/permissions'
import { FormInstance, FormItemRule } from 'element-plus'
import { iAddress, iCategory, iOrder, iOrderType, iPartnerData } from '@/types/models'
import { TableServiceType } from '@/composables/useServicesTable'
import OrderSummaryTable from '@/components/orders/OrderSummaryTable.vue'
import { iBaseFormState, iOrderBlockForm } from '@/types/forms'
import { isNumber } from 'lodash'
import InputDescription from '@/components/form/InputDescription.vue'
import { Connection } from '@element-plus/icons-vue'
import router from '@/router'
import MasterAutocomplete from '@/components/masters/MasterAutocomplete.vue'
import { pickAndAssign } from '@/utils/object'
import { iFormErrors } from '@/types/responses'
import OrderFileUpload from '@/components/orders/OrderFileUpload.vue'

const props = defineProps<{
  number: string
  expectedNumber: number | null
  index?: number
  services: Array<TableServiceType> | null
  orderType: iOrderType
  category: iCategory | undefined
  shopId: number
  isUpdateForm: boolean
  errors: iFormErrors<iOrderBlockForm>
  address: iAddress
}>()

const statuses = computed(() => store.getters.statuses)

const reqRule: FormItemRule = {
  required: true,
  trigger: 'blur',
  message: 'Укажите сумму',
  validator: (rule, value) => {
    return value > 0
  }
}

const store = useStore()
const formRef = ref<FormInstance>()

const state = reactive<iBaseFormState<iOrderBlockForm> & {
  linksToCalculation: {
    // eslint-disable-next-line no-unused-vars
    [key in keyof iOrderBlockForm]?: boolean
  }
}>({
  rules: {
    cost: [reqRule],
    payment_part_master: [reqRule],
    number: [{
      required: true,
      trigger: 'blur',
      message: 'Укажите номер'
    }]
  },
  form: {
    number: '',
    cost: null,
    type_id: props.orderType?.id || null,
    category_id: props.category?.id || null,
    is_payment_immediately: false,
    payment_part_shop: 0,
    payment_part_master: 0,
    payment_part_master_extra: 0,
    payment_part_extra: 0,
    payment_date_shop: null,
    payment_date_master: null,
    master_debt: 0,
    status_id: 0,
    files: [],
    note: '',
    note_inner: '',
    partner_id: store.state.user.partner_id || null,
    master_id: null,
    pre_master_id: null,
    is_doc_passed: false,
    has_debt: false,
    has_debt_extra: false
  },
  linksToCalculation: {
    payment_part_master: !props.isUpdateForm,
    payment_part_shop: !props.isUpdateForm,
    payment_part_master_extra: !props.isUpdateForm
  }
})

const can = computed<PermissionCanFunctionType>(() => store.getters.can)
const partners = computed(() => store.state.partners)
const selectMasterPath = computed(() => props.address
  ? router.resolve({
    name: 'masterPlanning',
    query: {
      categories: props.category?.id || undefined,
      address: [
        props.address.address_city,
        props.address.address_district,
        props.address.address_street,
        props.address.address_house
      ].filter(Boolean).join(', ')
    }
  }).fullPath
  : '')

const partnerId = store.state.partners[0]?.id || undefined

const calculationPaymentMaster = computed(() => {
  const cost = isNumber(state.form.cost) ? state.form.cost : 0
  const percentMaster = percents.value?.percent_master || 0
  const percentShop = percents.value?.percent_in_shop || 0

  return (state.form.is_payment_immediately ? percentMaster : percentShop) / 100 * cost
})
const calculationPaymentShop = computed(() => {
  const cost = isNumber(state.form.cost) ? state.form.cost : 0
  const percentShop = percents.value?.percent_in_shop || 0

  return percentShop / 100 * cost
})
const calculationPaymentMasterExtra = computed(() => {
  const cost = isNumber(state.form.payment_part_extra) ? state.form.payment_part_extra : 0
  const percentMasterExtra = percents.value?.percent_master_extra || 0
  const percentShopExtra = percents.value?.percent_in_shop_extra || 0

  return (state.form.is_payment_immediately ? percentMasterExtra : percentShopExtra) / 100 * cost
})
const unlinkCalculation = (prop: keyof iOrderBlockForm) => {
  state.linksToCalculation[prop] = false
}
const handlerConnectionCalc = (prop: keyof iOrderBlockForm) => {
  state.linksToCalculation[prop] = true
  recalculation()
}
const recalculation = () => {
  const data = percents.value || false
  if (data) {
    if (state.form.cost) {
      if (state.linksToCalculation.payment_part_master) {
        state.form.payment_part_master = calculationPaymentMaster.value
      }
      if (state.linksToCalculation.payment_part_shop) {
        state.form.payment_part_shop = calculationPaymentShop.value
      }
    }
    if (state.form.payment_part_extra) {
      if (state.linksToCalculation.payment_part_master_extra) {
        state.form.payment_part_master_extra = calculationPaymentMasterExtra.value
      }
    }
  }
}

const percents = computed<iPartnerData|undefined>(() => props.orderType ? store.state.percents.find((item) => item.shop_id === props.shopId && item.partner_id === partnerId && item.order_type_id === props.orderType.id) : undefined)
const expectedNumber = computed(() => props.expectedNumber ? (props.expectedNumber + (props.index !== undefined ? '/' + (props.index + 1) : '')) : '')

watch(() => props.number, () => {
  state.form.number = props.number + (props.index !== undefined ? '/' + (props.index + 1) : '')
}, { immediate: true })

watch(() => props.services, () => {
  state.form.cost = props.services ? props.services.reduce((carry, service) => carry + (service.price * (service.qty || 0)), 0) : 0
}, { immediate: true })

watch(() => [state.form.cost, state.form.payment_part_extra, state.form.is_payment_immediately].join(':'), recalculation, { immediate: true })

watchEffect(() => {
  let sum = 0
  if (state.form.is_payment_immediately) {
    sum += (state.form.cost || 0) - (state.form.payment_part_master || 0)
  }
  if (state.form.payment_part_extra) {
    sum += state.form.payment_part_extra - (state.form.payment_part_master_extra || 0)
  }

  state.form.master_debt = sum || 0
})

const assign = (params: iOrder) => {
  pickAndAssign(state.form, params)
}

const formData = computed(() => state.form)

defineExpose({
  formRef, formData, state, assign
})
</script>

<style scoped lang="scss">
.master_id-form_item {
  position: relative;

  :deep(.el-form-item__content) {
    position: initial;

    .select_master-link {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 22px;
    }
  }
}
</style>

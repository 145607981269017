import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DashboardPage from '@/pages/DashboardPage.vue'
import OrdersPage from '@/pages/OrdersPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import UsersPage from '@/pages/UsersPage.vue'
import ShopsPage from '@/pages/ShopsPage.vue'
import LocationsPage from '@/pages/LocationsPage.vue'
import ServiceCategoriesPage from '@/pages/ServiceCategoriesPage.vue'
import PartnersPage from '@/pages/PartnersPage.vue'
import MasterPlanningPage from '@/pages/MasterPlanningPage.vue'
import MastersPage from '@/pages/MastersPage.vue'
import OrderFormPage from '@/pages/OrderFormPage.vue'
import StatisticPage from '@/pages/StatisticPage.vue'
import HistoryPage from '@/pages/HistoryPage.vue'
import RolesPage from '@/pages/RolesPage.vue'
import DebtorsPage from '@/pages/DebtorsPage.vue'
import TasksPage from '@/pages/TasksPage.vue'
import ServicesPage from '@/pages/ServicesPage.vue'
import PartnersDataPage from '@/pages/PartnersDataPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardPage
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersPage
  },
  {
    path: '/orders/create',
    name: 'order-create',
    component: OrderFormPage
  },
  {
    path: '/orders/:id',
    name: 'order-update',
    component: OrderFormPage
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage
  },
  {
    path: '/shops',
    name: 'shops',
    component: ShopsPage
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesPage
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: StatisticPage
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryPage
  },
  {
    path: '/locations',
    name: 'locations',
    component: LocationsPage
  },
  {
    path: '/service-categories',
    name: 'serviceCategories',
    component: ServiceCategoriesPage
  },
  {
    path: '/partners',
    name: 'partners',
    component: PartnersPage
  },
  {
    path: '/parnters-data',
    name: 'partnersData',
    component: PartnersDataPage
  },
  {
    path: '/master-planning',
    name: 'masterPlanning',
    component: MasterPlanningPage
  },
  {
    path: '/masters',
    name: 'masters',
    component: MastersPage
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesPage
  },
  {
    path: '/debtors',
    name: 'debtors',
    component: DebtorsPage
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

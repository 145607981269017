<template>
  <el-upload
    v-model:file-list="files"
    :action="uploadAction"
    :headers="uploadHeaders"
    multiple
    :disabled="disabled"
    :before-remove="beforeFileRemove"
    :on-preview="handleFilePreview"
    :on-success="handleFileSuccess"
    :on-remove="handleFileRemove"
  >
    <el-button type="primary" :disabled="disabled">Добавить файл</el-button>
  </el-upload>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { UploadFiles } from 'element-plus/es/components/upload/src/upload'
import instance from '@/api/instance'
import { useStore } from '@/store'
import { ElMessageBox, UploadFile, UploadProps } from 'element-plus'
import { AxiosResponse } from 'axios'
import { downloadFile as downloadFileRequest } from '@/api/files'
import downloadFile from '@/utils/downloadFile'
import { iServerResponse } from '@/types/api'
import { iListItem, iOrderFile } from '@/types/models'

interface UploadedFileInterface extends Partial<UploadFile> {
  id?: number
}

const props = defineProps<{
  modelValue: Array<iOrderFile>,
  disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const files = ref<UploadFiles>([])

const store = useStore()

const uploadAction = instance.defaults.baseURL + 'files/upload'
const uploadHeaders = computed(() => ({ 'X-Api-Key': store.state.token }))

const beforeFileRemove: UploadProps['beforeRemove'] = (uploadFile) => ElMessageBox.confirm(`Удалить ${uploadFile.name} ?`)
  .then(() => true, () => false)

const handleFilePreview: UploadProps['onPreview'] = (uploadFile) => {
  const id = (uploadFile as UploadedFileInterface).id || (uploadFile.response as AxiosResponse<UploadedFileInterface>)?.data.id
  if (id) {
    downloadFileRequest(id).then(response => {
      downloadFile(response, uploadFile.name)
    })
  }
}

const handleFileSuccess = (response: iServerResponse<iListItem>) => {
  emit('update:modelValue', [...props.modelValue, response.data])
}

const handleFileRemove = (uploadFile: UploadFile) => {
  const response = uploadFile.response as iServerResponse<iListItem>
  const copy = [...props.modelValue]

  emit('update:modelValue', copy.splice(copy.findIndex(f => f.id === response.data.id), 1))
}
</script>
